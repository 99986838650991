import VideoCall from "../../components/pages/VideoCall/VideoCall";

const videoCallPrivate = [
    {
        id: 1,
        path: "/group-call/:id",
        element: <VideoCall />
    },
    
];

export default videoCallPrivate;
