export const countries = [
    {
        id: 1,
        value: "Afghanistan",
        label: "Afghanistan",
    },
    {
        id: 2,
        value: "Albania",
        label: "Albania",
    },
    {
        id: 3,
        value: "Algeria",
        label: "Algeria",
    },
    {
        id: 4,
        value: "Andorra",
        label: "Andorra",
    },
    {
        id: 5,
        value: "Angola",
        label: "Angola",
    },
    {
        id: 6,
        value: "Antigua and",
        label: "Antigua and",
    },
    {
        id: 7,
        value: "Barbuda",
        label: "Barbuda",
    },
    {
        id: 8,
        value: "Argentina",
        label: "Argentina",
    },
    {
        id: 9,
        value: "Armenia",
        label: "Armenia",
    },
    {
        id: 10,
        value: "Australia",
        label: "Australia",
    },
    {
        id: 11,
        value: "Austria",
        label: "Austria",
    },
    {
        id: 12,
        value: "Azerbaijan",
        label: "Azerbaijan",
    },
    {
        id: 13,
        value: "Bahamas",
        label: "Bahamas",
    },
    {
        id: 14,
        value: "Bahrain",
        label: "Bahrain",
    },
    {
        id: 15,
        value: "Bangladesh",
        label: "Bangladesh",
    },
    {
        id: 16,
        value: "Barbados",
        label: "Barbados",
    },
    {
        id: 17,
        value: "Belarus",
        label: "Belarus",
    },
    {
        id: 18,
        value: "Belgium",
        label: "Belgium",
    },
    {
        id: 19,
        value: "Belize",
        label: "Belize",
    },
    {
        id: 20,
        value: "Benin",
        label: "Benin",
    },
    {
        id: 21,
        value: "Bhutan",
        label: "Bhutan",
    },
    {
        id: 22,
        value: "Bolivia",
        label: "Bolivia",
    },
    {
        id: 23,
        value: "Bosnia and Herzegovina",
        label: "Bosnia and Herzegovina",
    },
    {
        id: 24,
        value: "Botswana",
        label: "Botswana",
    },
    {
        id: 25,
        value: "Brazil",
        label: "Brazil",
    },
    {
        id: 26,
        value: "Brunei",
        label: "Brunei",
    },
    {
        id: 27,
        value: "Bulgaria",
        label: "Bulgaria",
    },
    {
        id: 28,
        value: "Burkina Faso",
        label: "Burkina Faso",
    },
    {
        id: 29,
        value: "Burundi",
        label: "Burundi",
    },
    {
        id: 30,
        value: "Cabo Verde",
        label: "Cabo Verde",
    },
    {
        id: 31,
        value: "Cambodia",
        label: "Cambodia",
    },
    {
        id: 32,
        value: "Cameroon",
        label: "Cameroon",
    },
    {
        id: 33,
        value: "Canada",
        label: "Canada",
    },
    {
        id: 34,
        value: "Central African",
        label: "Central African",
    },
    {
        id: 35,
        value: "Republic (CAR)",
        label: "Republic (CAR)",
    },
    {
        id: 36,
        value: "Chad",
        label: "Chad",
    },
    {
        id: 37,
        value: "Chile",
        label: "Chile",
    },
    {
        id: 38,
        value: "China",
        label: "China",
    },
    {
        id: 39,
        value: "Colombia",
        label: "Colombia",
    },
    {
        id: 40,
        value: "Comoros",
        label: "Comoros",
    },
    {
        id: 41,
        value: "Congo, Democratic Republic of the",
        label: "Congo, Democratic Republic of the",
    },
    {
        id: 42,
        value: "Congo, Republic of the",
        label: "Congo, Republic of the",
    },
    {
        id: 43,
        value: "Costa Rica",
        label: "Costa Rica",
    },
    {
        id: 44,
        value: "Cote d'Ivoire",
        label: "Cote d'Ivoire",
    },
    {
        id: 45,
        value: "Croatia",
        label: "Croatia",
    },
    {
        id: 46,
        value: "Cuba",
        label: "Cuba",
    },
    {
        id: 47,
        value: "Cyprus",
        label: "Cyprus",
    },
    {
        id: 48,
        value: "Czechia",
        label: "Czechia",
    },
    {
        id: 49,
        value: "Denmark",
        label: "Denmark",
    },
    {
        id: 50,
        value: "Djibouti",
        label: "Djibouti",
    },
    {
        id: 51,
        value: "Dominica",
        label: "Dominica",
    },
    {
        id: 52,
        value: "Dominican Republic",
        label: "Dominican Republic",
    },
    {
        id: 53,
        value: "Ecuador",
        label: "Ecuador",
    },
    {
        id: 54,
        value: "Egypt",
        label: "Egypt",
    },
    {
        id: 55,
        value: "El Salvador",
        label: "El Salvador",
    },
    {
        id: 56,
        value: "Equatorial Guinea",
        label: "Equatorial Guinea",
    },
    {
        id: 57,
        value: "Eritrea",
        label: "Eritrea",
    },
    {
        id: 58,
        value: "Estonia",
        label: "Estonia",
    },
    {
        id: 59,
        value: "Eswatini",
        label: "Eswatini",
    },
    {
        id: 60,
        value: "Ethiopia",
        label: "Ethiopia",
    },
    {
        id: 61,
        value: "Fiji",
        label: "Fiji",
    },
    {
        id: 62,
        value: "Finland",
        label: "Finland",
    },
    {
        id: 63,
        value: "France",
        label: "France",
    },
    {
        id: 64,
        value: "Gabon",
        label: "Gabon",
    },
    {
        id: 65,
        value: "Gambia",
        label: "Gambia",
    },
    {
        id: 66,
        value: "Georgia",
        label: "Georgia",
    },
    {
        id: 67,
        value: "Germany",
        label: "Germany",
    },
    {
        id: 68,
        value: "Ghana",
        label: "Ghana",
    },
    {
        id: 69,
        value: "Greece",
        label: "Greece",
    },
    {
        id: 70,
        value: "Grenada",
        label: "Grenada",
    },
    {
        id: 71,
        value: "Guatemala",
        label: "Guatemala",
    },
    {
        id: 72,
        value: "Guinea",
        label: "Guinea",
    },
    {
        id: 73,
        value: "Guinea-Bissau",
        label: "Guinea-Bissau",
    },
    {
        id: 74,
        value: "Guyana",
        label: "Guyana",
    },
    {
        id: 75,
        value: "Haiti",
        label: "Haiti",
    },
    {
        id: 76,
        value: "Honduras",
        label: "Honduras",
    },
    {
        id: 77,
        value: "Hungary",
        label: "Hungary",
    },
    {
        id: 78,
        value: "Iceland",
        label: "Iceland",
    },
    {
        id: 79,
        value: "India",
        label: "India",
    },
    {
        id: 80,
        value: "Indonesia",
        label: "Indonesia",
    },
    {
        id: 81,
        value: "Iran",
        label: "Iran",
    },
    {
        id: 82,
        value: "Iraq",
        label: "Iraq",
    },
    {
        id: 83,
        value: "Ireland",
        label: "Ireland",
    },
    {
        id: 84,
        value: "Israel",
        label: "Israel",
    },
    {
        id: 85,
        value: "Italy",
        label: "Italy",
    },
    {
        id: 86,
        value: "Jamaica",
        label: "Jamaica",
    },
    {
        id: 87,
        value: "Japan",
        label: "Japan",
    },
    {
        id: 88,
        value: "Jordan",
        label: "Jordan",
    },
    {
        id: 89,
        value: "Kazakhstan",
        label: "Kazakhstan",
    },
    {
        id: 90,
        value: "Kenya",
        label: "Kenya",
    },
    {
        id: 91,
        value: "Kiribati",
        label: "Kiribati",
    },
    {
        id: 92,
        value: "Kosovo",
        label: "Kosovo",
    },
    {
        id: 93,
        value: "Kuwait",
        label: "Kuwait",
    },
    {
        id: 94,
        value: "Kyrgyzstan",
        label: "Kyrgyzstan",
    },
    {
        id: 95,
        value: "Laos",
        label: "Laos",
    },
    {
        id: 96,
        value: "Latvia",
        label: "Latvia",
    },
    {
        id: 97,
        value: "Lebanon",
        label: "Lebanon",
    },
    {
        id: 98,
        value: "Lesotho",
        label: "Lesotho",
    },
    {
        id: 99,
        value: "Liberia",
        label: "Liberia",
    },
    {
        id: 100,
        value: "Libya",
        label: "Libya",
    },
    {
        id: 101,
        value: "Liechtenstein",
        label: "Liechtenstein",
    },
    {
        id: 102,
        value: "Lithuania",
        label: "Lithuania",
    },
    {
        id: 103,
        value: "Luxembourg",
        label: "Luxembourg",
    },
    {
        id: 104,
        value: "Madagascar",
        label: "Madagascar",
    },
    {
        id: 105,
        value: "Malawi",
        label: "Malawi",
    },
    {
        id: 106,
        value: "Malaysia",
        label: "Malaysia",
    },
    {
        id: 107,
        value: "Maldives",
        label: "Maldives",
    },
    {
        id: 108,
        value: "Mali",
        label: "Mali",
    },
    {
        id: 109,
        value: "Malta",
        label: "Malta",
    },
    {
        id: 110,
        value: "Marshall Islands",
        label: "Marshall Islands",
    },
    {
        id: 111,
        value: "Mauritania",
        label: "Mauritania",
    },
    {
        id: 112,
        value: "Mauritius",
        label: "Mauritius",
    },
    {
        id: 113,
        value: "Mexico",
        label: "Mexico",
    },
    {
        id: 114,
        value: "Micronesia",
        label: "Micronesia",
    },
    {
        id: 115,
        value: "Moldova",
        label: "Moldova",
    },
    {
        id: 116,
        value: "Monaco",
        label: "Monaco",
    },
    {
        id: 117,
        value: "Mongolia",
        label: "Mongolia",
    },
    {
        id: 118,
        value: "Montenegro",
        label: "Montenegro",
    },
    {
        id: 119,
        value: "Morocco",
        label: "Morocco",
    },
    {
        id: 120,
        value: "Mozambique",
        label: "Mozambique",
    },
    {
        id: 121,
        value: "Myanmar",
        label: "Myanmar",
    },
    {
        id: 122,
        value: "Namibia",
        label: "Namibia",
    },
    {
        id: 123,
        value: "Nauru",
        label: "Nauru",
    },
    {
        id: 124,
        value: "Nepal",
        label: "Nepal",
    },
    {
        id: 125,
        value: "Netherlands",
        label: "Netherlands",
    },
    {
        id: 126,
        value: "New Zealand",
        label: "New Zealand",
    },
    {
        id: 127,
        value: "Nicaragua",
        label: "Nicaragua",
    },
    {
        id: 128,
        value: "Niger",
        label: "Niger",
    },
    {
        id: 129,
        value: "Nigeria",
        label: "Nigeria",
    },
    {
        id: 130,
        value: "North Korea",
        label: "North Korea",
    },
    {
        id: 131,
        value: "North Macedonia",
        label: "North Macedonia",
    },
    {
        id: 132,
        value: "Norway",
        label: "Norway",
    },
    {
        id: 133,
        value: "Oman",
        label: "Oman",
    },
    {
        id: 134,
        value: "Pakistan",
        label: "Pakistan",
    },
    {
        id: 135,
        value: "Palau",
        label: "Palau",
    },
    {
        id: 136,
        value: "Palestine",
        label: "Palestine",
    },
    {
        id: 137,
        value: "Panama",
        label: "Panama",
    },
    {
        id: 138,
        value: "Papua New Guinea",
        label: "Papua New Guinea",
    },
    {
        id: 139,
        value: "Paraguay",
        label: "Paraguay",
    },
    {
        id: 140,
        value: "Peru",
        label: "Peru",
    },
    {
        id: 141,
        value: "Philippines",
        label: "Philippines",
    },
    {
        id: 142,
        value: "Poland",
        label: "Poland",
    },
    {
        id: 143,
        value: "Portugal",
        label: "Portugal",
    },
    {
        id: 144,
        value: "Qatar",
        label: "Qatar",
    },
    {
        id: 145,
        value: "Romania",
        label: "Romania",
    },
    {
        id: 146,
        value: "Russia",
        label: "Russia",
    },
    {
        id: 147,
        value: "Rwanda",
        label: "Rwanda",
    },
    {
        id: 148,
        value: "Saint Kitts and Nevis",
        label: "Saint Kitts and Nevis",
    },
    {
        id: 149,
        value: "Saint Lucia",
        label: "Saint Lucia",
    },
    {
        id: 150,
        value: "Saint Vincent and the Grenadines",
        label: "Saint Vincent and the Grenadines",
    },
    {
        id: 151,
        value: "Samoa",
        label: "Samoa",
    },
    {
        id: 152,
        value: "San Marino",
        label: "San Marino",
    },
    {
        id: 153,
        value: "Sao Tome and Principe",
        label: "Sao Tome and Principe",
    },
    {
        id: 154,
        value: "Saudi Arabia",
        label: "Saudi Arabia",
    },
    {
        id: 155,
        value: "Senegal",
        label: "Senegal",
    },
    {
        id: 156,
        value: "Serbia",
        label: "Serbia",
    },
    {
        id: 157,
        value: "Seychelles",
        label: "Seychelles",
    },
    {
        id: 158,
        value: "Sierra Leone",
        label: "Sierra Leone",
    },
    {
        id: 159,
        value: "Singapore",
        label: "Singapore",
    },
    {
        id: 160,
        value: "Slovakia",
        label: "Slovakia",
    },
    {
        id: 161,
        value: "Slovenia",
        label: "Slovenia",
    },
    {
        id: 162,
        value: "Solomon Islands",
        label: "Solomon Islands",
    },
    {
        id: 163,
        value: "Somalia",
        label: "Somalia",
    },
    {
        id: 164,
        value: "South Africa",
        label: "South Africa",
    },
    {
        id: 165,
        value: "South Korea",
        label: "South Korea",
    },
    {
        id: 166,
        value: "South Sudan",
        label: "South Sudan",
    },
    {
        id: 167,
        value: "Spain",
        label: "Spain",
    },
    {
        id: 168,
        value: "Sri Lanka",
        label: "Sri Lanka",
    },
    {
        id: 169,
        value: "Sudan",
        label: "Sudan",
    },
    {
        id: 170,
        value: "Suriname",
        label: "Suriname",
    },
    {
        id: 171,
        value: "Sweden",
        label: "Sweden",
    },
    {
        id: 172,
        value: "Switzerland",
        label: "Switzerland",
    },
    {
        id: 173,
        value: "Syria",
        label: "Syria",
    },
    {
        id: 174,
        value: "Taiwan",
        label: "Taiwan",
    },
    {
        id: 175,
        value: "Tajikistan",
        label: "Tajikistan",
    },
    {
        id: 176,
        value: "Tanzania",
        label: "Tanzania",
    },
    {
        id: 177,
        value: "Thailand",
        label: "Thailand",
    },
    {
        id: 178,
        value: "Timor-Leste",
        label: "Timor-Leste",
    },
    {
        id: 179,
        value: "Togo",
        label: "Togo",
    },
    {
        id: 180,
        value: "Tonga",
        label: "Tonga",
    },
    {
        id: 181,
        value: "Trinidad and Tobago",
        label: "Trinidad and Tobago",
    },
    {
        id: 182,
        value: "Tunisia",
        label: "Tunisia",
    },
    {
        id: 183,
        value: "Turkey",
        label: "Turkey",
    },
    {
        id: 184,
        value: "Turkmenistan",
        label: "Turkmenistan",
    },
    {
        id: 185,
        value: "Tuvalu",
        label: "Tuvalu",
    },
    {
        id: 186,
        value: "Uganda",
        label: "Uganda",
    },
    {
        id: 187,
        value: "Ukraine",
        label: "Ukraine",
    },
    {
        id: 188,
        value: "United Arab",
        label: "United Arab",
    },
    {
        id: 189,
        value: "Emirates (UAE)",
        label: "Emirates (UAE)",
    },
    {
        id: 190,
        value: "United Kingdom (UK)",
        label: "United Kingdom (UK)",
    },
    {
        id: 191,
        value: "United States of America (USA)",
        label: "United States of America (USA)",
    },
    {
        id: 192,
        value: "Uruguay",
        label: "Uruguay",
    },
    {
        id: 193,
        value: "Uzbekistan",
        label: "Uzbekistan",
    },
    {
        id: 194,
        value: "Vanuatu",
        label: "Vanuatu",
    },
    {
        id: 195,
        value: "Vatican City (Holy See)",
        label: "Vatican City (Holy See)",
    },
    {
        id: 196,
        value: "Venezuela",
        label: "Venezuela",
    },
    {
        id: 197,
        value: "Vietnam",
        label: "Vietnam",
    },
    {
        id: 198,
        value: "Yemen",
        label: "Yemen",
    },
    {
        id: 199,
        value: "Zambia",
        label: "Zambia",
    },
    {
        id: 200,
        value: "Zimbabwe",
        label: "Zimbabwe",
    },
];
