export const hometown = [
    {
        value: "Chittagong",
    },
    {
        value: "Dhaka",
    },
    {
        value: "Rajshahi",
    },
    {
        value: "Sylhet",
    },
    {
        value: "Jessore",
    },
    {
        value: "Dinajpur",
    },
    {
        value: "Mymensingh",
    },
    {
        value: "Comilla",
    },
    {
        value: "Barisal",
    },
    {
        value: "Faridpur",
    },
    {
        value: "Bogra",
    },
    {
        value: "Pabna",
    },
    {
        value: "Rangamati",
    },
    {
        value: "Kushtia",
    },
    {
        value: "Rangpur",
    },
    {
        value: "Noakhali",
    },
    {
        value: "Khulna",
    },
    {
        value: "Tangail",
    },
    {
        value: "Panchagarh",
    },
    {
        value: "Bhola",
    },
    {
        value: "Bandarban",
    },
    {
        value: "Chandpur",
    },
    {
        value: "Habiganj",
    },
    {
        value: "Lakshmipur",
    },
    {
        value: "Barguna",
    },
    {
        value: "Jhalokati",
    },
    {
        value: "Pirojpur",
    },
    {
        value: "Patuakhali",
    },
    {
        value: "Jhenaidah",
    },
    {
        value: "Narail",
    },
    {
        value: "Magura",
    },
    {
        value: "Lalmonirhat",
    },
    {
        value: "Kurigram",
    },
    {
        value: "Nilphamari",
    },
    {
        value: "Gaibandha",
    },
    {
        value: "Thakurgaon",
    },
    {
        value: "Satkhira",
    },
    {
        value: "Bagerhat",
    },
    {
        value: "Chuadanga",
    },
    {
        value: "Meherpur",
    },
    {
        value: "Sirajganj",
    },
    {
        value: "Joypurhat",
    },
    {
        value: "Natore",
    },
    {
        value: "Naogaon",
    },
    {
        value: "Nawabganj",
    },
    {
        value: "Khagrachhari",
    },
    {
        value: "Feni",
    },
    {
        value: "Brahmanbaria",
    },
    {
        value: "Sunamganj",
    },
    {
        value: "Cox's Bazar",
    },
    {
        value: "Moulvibazar",
    },
    {
        value: "Gopalganj",
    },
    {
        value: "Shariatpur",
    },
    {
        value: "Madaripur",
    },
    {
        value: "Rajbari",
    },
    {
        value: "Gazipur",
    },
    {
        value: "Kishoreganj",
    },
    {
        value: "Jamalpur",
    },
    {
        value: "Sherpur",
    },
    {
        value: "Netrakona",
    },
    {
        value: "Munshiganj",
    },
    {
        value: "Narsingdi",
    },
    {
        value: "Narayanganj",
    },
    {
        value: "Manikganj",
    },
];
